import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';

import { FieldsEditRestriction } from '../../../shared/utils/fieldEditRestriction';
import { getEditableObject, getPopUpMessagesText } from '../helpers';
import confirm from '../helpers/confirm';
import { Freight, Transporter } from '../helpers/types/index';
import ApiService from '../services/ApiService';
import { RootState } from './';

export type State = {
  editedFreight?: Freight;
  originalFreight?: Freight;
  transporters?: Transporter[];
  isSaveAccepted: boolean;
  freightStatuses: string[];
  infoDialog: boolean;
  response: string;
};

const state: State = {
  editedFreight: undefined,
  originalFreight: undefined,
  transporters: undefined,
  isSaveAccepted: false,
  freightStatuses: [],
  infoDialog: false,
  response: ''
};

const actions: ActionTree<State, RootState> = {
  async applyChanges({ commit, dispatch, state }) {
    await dispatch('saveFreight');
    const savedFreight = (
      await ApiService.getFreights({
        pagination: -1,
        filter: { freightId: state.editedFreight?.freightId },
        statuses: undefined
      })
    ).data.result[0];
    commit('editFreight', savedFreight);
  },

  async saveFreight({ commit, rootGetters, rootState }, element?: any) {
    commit('isSaveAccepted', false);
    const today = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    ).toISOString();

    const popupMessages: string[] = [];

    if (
      state.editedFreight?.status !== rootState.app.enums.freightStatus.new &&
      state.editedFreight?.status &&
      state.freightStatuses.indexOf(state.editedFreight.status) >=
        state.freightStatuses.indexOf(rootState.app.enums.freightStatus.published) &&
      !rootGetters['account/isTransporter']
    ) {
      popupMessages.push('a fuvarhoz már tartozik kiajánlott/válaszolt/elfogadott fuvarozó');
    } else if (
      state.originalFreight?.status === rootState.app.enums.freightStatus.scheduled &&
      ((element && element.deliveryDate !== state.originalFreight?.deliveryDate) ||
        state.editedFreight?.deliveryDate !== state.originalFreight?.deliveryDate)
    ) {
      popupMessages.push('a fuvar már be van ütemezve');
    }

    if (state.editedFreight?.deliveryDate && state.editedFreight?.deliveryDate < today) {
      popupMessages.push('a módosítás múltbéli időpontra vonatkozik');
    }

    if (
      state.editedFreight?.deliveryDate &&
      state.editedFreight.releaseDate &&
      state.editedFreight?.deliveryDate < state.editedFreight?.releaseDate
    ) {
      // TODO WB
      popupMessages.push('a szállítás dátuma korábbi, mint a visszaigazolt dátum');
    }

    if (
      state.editedFreight?.expectedDeadlineDate &&
      state.editedFreight?.deliveryDate &&
      state.editedFreight?.expectedDeadlineDate < state.editedFreight?.deliveryDate
    ) {
      popupMessages.push('A szállítási dátum későbbi mint a várható szállítási határidő.');
    }

    if (
      state.originalFreight?.status !== rootState.app.enums.freightStatus.new &&
      state.editedFreight?.status !== rootState.app.enums.freightStatus.new &&
      state.originalFreight?.truckType !== state.originalFreight?.truckType
    ) {
      popupMessages.push('a fuvar már nem "új" státuszú');
    }

    if (
      state.editedFreight?.status === rootState.app.enums.freightStatus.scheduled &&
      state.freightStatuses.indexOf(state.originalFreight?.status) >
        state.freightStatuses.indexOf(rootState.app.enums.freightStatus.scheduled)
    ) {
      let message = 'a fuvar rögzítve állapotva állításával a fuvarhoz tartozó ajánlatok';
      if (
        state.freightStatuses.indexOf(state.originalFreight?.status) >
        state.freightStatuses.indexOf(rootState.app.enums.freightStatus.published)
      ) {
        message += ' és a fuvarozó';
      }
      if (
        rootGetters['module/isTimeSlotModuleActive'] &&
        state.freightStatuses.indexOf(state.originalFreight?.status) >
          state.freightStatuses.indexOf(rootState.app.enums.freightStatus.accepted)
      ) {
        message = message.replace(' és ', ', ') + ', idősáv, dokk és az időszeletek száma';
      }
      popupMessages.push(`${message} törlésre kerülnek`);
    }

    if (
      rootGetters['module/isTimeSlotModuleActive'] &&
      state.freightStatuses.indexOf(state.editedFreight?.status) >=
        state.freightStatuses.indexOf(rootState.app.enums.freightStatus.arrived) &&
      !state.editedFreight?.timeSlot?.dockId
    ) {
      popupMessages.push('a fuvarhoz nincs dokk rendelve');
    }

    if (
      (state.editedFreight?.offerSentToTransporterIds?.length ||
        state.editedFreight?.transporterId) &&
      state.editedFreight?.status === rootState.app.enums.freightStatus.new &&
      !rootGetters['account/isTransporter']
    ) {
      popupMessages.push(
        'társítva van fuvarozó, ' +
          (rootGetters['module/isFreightOfferModuleActive']
            ? ` vagy már lett ${
                rootGetters['module/isTransporterTenderActive'] ? 'ajánlatkérés' : 'megbízás'
              } kiküldve erre a fuvarra, `
            : '') +
          'de a státusza "új"<br>' +
          '(az "új" állapotú fuvarok adatai az automatikus feldolgozások miatt változhatnak,' +
          ' a véglegesítéshez érdemes rögzíteni a fuvart.)'
      );
    }

    /**
     * If freight was finalized, and user changes status
     * or overrides platenumber or driver's name.
     */
    if (
      (state.originalFreight?.status === rootState.app.enums.freightStatus.finalized &&
        state.editedFreight?.status !== state.originalFreight?.status &&
        !rootGetters['account/isTransporter']) ||
      (state.originalFreight?.status === rootState.app.enums.freightStatus.finalized &&
        (state.originalFreight?.truckLicensePlateNumber !==
          state.editedFreight?.truckLicensePlateNumber ||
          state.originalFreight?.driverName !== state.editedFreight?.driverName))
    ) {
      popupMessages.push(
        'a fuvarozó korábban már elfogadta és véglegesítette ezt a fuvart,<br>' +
          ' bármilyen, a lehívást követő változtatásról már nem biztos, ' +
          'hogy a fuvarozó időben értesülni fog<br>'
      );
    }

    let answer: boolean | undefined = true;

    if (popupMessages.length > 0) {
      const messages = getPopUpMessagesText(popupMessages);
      answer = await confirm(
        'A fuvar módosításánál a következő problémák merülhetnek fel: <br><br>' +
          messages +
          'Továbbra is módosítani szeretné a fuvart?',
        {
          title: 'Fuvar módosítása',
          width: 550,
          buttonTrueText: 'Mentés',
          buttonFalseText: 'Vissza'
        }
      );
    }

    if (answer) {
      commit('isSaveAccepted', true);
      const config = rootState.app.config;
      const fieldEditRestrictions = FieldsEditRestriction.init(config);
      if (element) {
        const editedObject = await getEditableObject(
          element,
          rootGetters['account/role'],
          fieldEditRestrictions.FREIGHT_EDIT_MODULE_DEPENDENCIES,
          fieldEditRestrictions.FREIGHT_FIELDS_EDIT_RESTRICTIONS
        );
        await ApiService.saveFreight(element.id, element.__v, editedObject).catch(() => {
          /* no-op */
        });
      } else if (state.editedFreight) {
        const freight = JSON.parse(JSON.stringify(state.editedFreight));
        Object.assign(freight, {
          freightCost: parseFloat((state.editedFreight as any).freightCost),
          freightIncome: parseFloat((state.editedFreight as any).freightIncome)
        });

        if (rootGetters['module/isTimeSlotModuleActive']) {
          if (!rootGetters['module/isTimeSlotWidthActive']) {
            freight.timeSlot.timeSlotWidth = undefined;
          }

          if (!freight.timeSlot.time) {
            freight.timeSlot.time = undefined;
            freight.timeSlot.dockId = undefined;
            freight.timeSlot.dockName = undefined;
          }
        }

        const editedObject = await getEditableObject(
          freight,
          rootGetters['account/role'],
          fieldEditRestrictions.FREIGHT_EDIT_MODULE_DEPENDENCIES,
          fieldEditRestrictions.FREIGHT_FIELDS_EDIT_RESTRICTIONS
        );
        await ApiService.saveFreight(
          state.editedFreight.id,
          state.editedFreight.__v,
          editedObject
        ).catch(() => {
          /* no-op */
        });
      }
    }
  },

  editFreight({ commit }, freight?: Freight) {
    commit('editFreight', freight);
  },

  setFreightProperties({ commit }, properties: {}) {
    commit('setProperties', properties);
  },

  setFreightBillProperties({ commit }, properties: {}) {
    commit('setBillProperties', properties);
  },

  async getTransporters(
    { commit },
    { status, inactiveVehiclesInclude }: { status: string; inactiveVehiclesInclude?: boolean }
  ) {
    const { data } = await ApiService.getTransporters({ status });
    const filteredTransporters: Transporter[] = [];
    for (const transporter of data) {
      if (
        inactiveVehiclesInclude &&
        (transporter.availableVehicles.includes(state.editedFreight?.truckType) ||
          transporter.inactiveVehicles.includes(state.editedFreight?.truckType))
      ) {
        filteredTransporters.push(transporter);
      } else if (transporter.availableVehicles.includes(state.editedFreight?.truckType)) {
        filteredTransporters.push(transporter);
      }

      if (state.editedFreight?.alternatives && state.editedFreight?.alternatives?.length > 0) {
        for (const alternative of state.editedFreight.alternatives) {
          if (transporter.availableVehicles.includes(alternative.type)) {
            filteredTransporters.push(transporter);
          }
        }
      }
    }
    commit('setTransporters', filteredTransporters);
  },

  setTransporters({ commit }, transporters: []) {
    commit('setTransporters', transporters);
  },

  updateTruckType({ commit }, type: string) {
    commit('updateTruckType', type);
  },

  setPrevStatus({ commit, rootState, state }) {
    if (state.editedFreight?.status) {
      const prevStatus = state.freightStatuses[
        state.freightStatuses.indexOf(state.editedFreight.status) - 1
      ]
        ? state.freightStatuses[state.freightStatuses.indexOf(state.editedFreight.status) - 1]
        : undefined;
      if (prevStatus) {
        if (prevStatus === rootState.app.enums.freightStatus.new) {
          commit('setTimeSlotProperties', { time: undefined, dockId: undefined });
        }
        commit('setStatus', prevStatus);
      }
    }
  },

  setNextStatus({ commit, state }) {
    if (state.editedFreight?.status) {
      const nextStatus = state.freightStatuses[
        state.freightStatuses.indexOf(state.editedFreight.status) + 1
      ]
        ? state.freightStatuses[state.freightStatuses.indexOf(state.editedFreight.status) + 1]
        : undefined;
      if (nextStatus) {
        commit('setStatus', nextStatus);
      }
    }
  },

  async setFreightStatuses({ commit, rootGetters, rootState }, isEditDialog: boolean = false) {
    let freightStatuses = (await ApiService.getFreightStatuses()).data;
    // remove cancelled freight status
    // - order closure module is not active (in this case the status is not used)
    // - if used in edit dialog (in this case the status cannot be changed on the dialog,
    //   there is a separate cancel button)
    if (!rootGetters['module/isOrderClosureModuleActive'] || isEditDialog) {
      freightStatuses = freightStatuses.filter(
        (status: string) => status !== rootState.app.enums.freightStatus.cancelled
      );
    }
    commit('setFreightsStatuses', freightStatuses);
  },

  // eslint-disable-next-line no-empty-pattern
  async setBillingData({}) {
    await ApiService.setFreightBillingData(
      state.editedFreight?.freightId,
      state.editedFreight?.bill
    );
  },

  // eslint-disable-next-line no-empty-pattern
  async setFreightComment({}) {
    await ApiService.setFreightComment(
      state.editedFreight?.freightId,
      state.editedFreight?.comment
    );
  },

  // eslint-disable-next-line no-empty-pattern
  async undoBill({}, freightId: string) {
    await ApiService.undoBill(freightId);
  },

  async cancelFreight({ dispatch }, freightId: string) {
    const { status } = await ApiService.cancelFreight(freightId);
    if (status === 200) {
      dispatch('showResponse', 'Fuvar sikeresen lemondva!');
    }
  },

  async uncancelFreight({ dispatch }, freightId: string) {
    const { status } = await ApiService.uncancelFreight(freightId);
    if (status === 200) {
      dispatch('showResponse', 'Fuvar sikeresen újranyitva!');
    }
  },

  showResponse({ commit }, response: string) {
    commit('showResponse', response);
  },

  closeInfoDialog({ commit }) {
    commit('closeInfoDialog');
  }
};

const mutations: MutationTree<State> = {
  editFreight(state, freight?: Freight) {
    if (!freight) {
      state.editedFreight = freight;
      return;
    }

    if (freight?.timeSlot && !freight.timeSlot.timeSlotWidth) {
      freight.timeSlot.timeSlotWidth = 1;
    }
    state.editedFreight = freight;
    state.originalFreight = JSON.parse(JSON.stringify(freight));
  },

  setProperties(state, properties: {}) {
    state.editedFreight = {
      ...state.editedFreight,
      ...properties
    };
  },

  setBillProperties(state, billProperties: {}) {
    state.editedFreight!.bill = {
      ...state.editedFreight?.bill,
      ...billProperties
    };
  },

  setTimeSlotProperties(state, timeSlotProperties: {}) {
    state.editedFreight!.timeSlot = {
      ...state.editedFreight?.timeSlot,
      ...timeSlotProperties
    };
  },

  setTransporters(state, data: []) {
    state.transporters = data;
  },

  isSaveAccepted(state, payload: boolean) {
    state.isSaveAccepted = payload;
  },
  setStatus(state, status: string) {
    if (state.editedFreight) {
      state.editedFreight.status = status;
    }
  },

  setFreightsStatuses(state, freightStatuses: string[]) {
    state.freightStatuses = freightStatuses;
  },

  showResponse(state, response: string) {
    state.response = response;
    state.infoDialog = true;
  },

  closeInfoDialog(state) {
    state.infoDialog = false;
  },

  updateField
};

const getters: GetterTree<State, RootState> = {
  isFinalized: (state, getters, rootState) =>
    state.originalFreight?.status === rootState.app.enums.freightStatus.finalized,
  isShipped: (state, getters, rootState) =>
    state.originalFreight?.status === rootState.app.enums.freightStatus.shipped,
  getField
};

export const freight: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
