import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '.';
import ApiService from '../services/ApiService';
import { AxiosError } from 'axios';

export type Product = {
  id?: string;
  itemId?: string;
  name?: string;
  unit?: string;
  kgPerUnit?: number;
  kgPerPallet?: number;
  unitPerPallet?: number;
  palletType?: string;
};

type State = {
  editedProduct?: Product;
  filteredProducts: Product[];
  filteredCount: number;
  isSaving: boolean;
};

const state: State = {
  editedProduct: undefined,
  filteredProducts: [],
  filteredCount: 0,
  isSaving: false
};

const actions: ActionTree<State, RootState> = {
  async loadProducts({ commit }, { pagination, filter }) {
    try {
      const { data } = await ApiService.getProducts({ pagination, filter });
      const products: Product[] = data.result;
      const filteredCount: number = data.filteredCount;
      commit('refresh', products);
      commit('setFilteredCount', filteredCount);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_CANCELED') {
          return;
        }
      }

      throw error;
    }
  },

  editProduct({ commit }, product: Product) {
    commit('edit', product);
  },

  async saveEditedProduct({ commit, dispatch }) {
    commit('isSaving', true);
    await ApiService.updateProduct(state.editedProduct);
    dispatch('loadProducts');
    commit('clear');
  },

  cancel({ commit }) {
    commit('clear');
  }
};

const mutations: MutationTree<State> = {
  setFilteredCount(state, count: number) {
    state.filteredCount = count;
  },

  refresh(state, products: Product[]) {
    state.filteredProducts = products;
  },

  edit(state, product: Product) {
    state.editedProduct = product;
  },

  clear(state) {
    state.editedProduct = undefined;
    state.isSaving = false;
  },

  isSaving(state, isSaving: boolean) {
    state.isSaving = isSaving;
  }
};

const getters: GetterTree<State, RootState> = {};

export const products = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
